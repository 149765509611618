import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
const SubjectShowList = () => {
  const { id } = useParams();
  // console.log("courseid",id)
  const [subData, setSubData] = useState([]);

  // console.log("subData",subData.length);
  useEffect(() => {
    fetchSubData();
  }, []);

  const fetchSubData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/offline-course-topic-withsubject`,
        {
          subject_id: id,
          user_id: "22541",
          offline_live_course_id: id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responseData", response.data.data);
      // console.log("responseNewSubject", response.data.new_subject);
      // console.log("responseSubject", response.data.subject);
      setSubData(response.data.subject);
    } catch (error) {
      console.log("error fetching subject Data", error);
    }
  };
  // https://test.qualityeducation.in/api/offline-course-topic-withsubject
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Subject List
              </h4>
              <Link to="/subjectadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Subject
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Subject List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Total Subject ({subData.length}) </h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>Subject Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subData.map((curElem, index) => {
                      // console.log("subjectId",curElem);
                      return (
                        <tr key={curElem.id}>
                          <th>{index + 1}</th>
                          <th>
                            <img
                              src={curElem.image}
                              className="img-40"
                              alt="image"
                            />
                          </th>
                          <th>
                            <Link to={`/topiclist/${curElem.id}/${id}`}>
                              {curElem.title}
                            </Link>
                          </th>
                          <th>
                            <Link
                              // to={`/editbanner/${curElem._id}`}
                              className="mx-2"
                            >
                              <img src={edit} alt="" />
                            </Link>
                            <button className="mx-2">
                              <img
                                src={trash}
                                alt="delete"
                                // onClick={() => handleDelete(curElem._id)}
                              />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubjectShowList;

// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import BASE_URL from "./config/baseUrl";
// import { toast, Bounce } from "react-toastify";

// export default function Protected({ Component  }) {
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const role = localStorage.getItem("role");
//     // const role = localStorage.getItem("user");
    
//     const checkAuth = async () => {
//       if (!token) {
//         localStorage.clear()
//         navigate("/login");
//         return;
//       }
       
//       // console.log(allowedRoles);

//       try {
//         // let response = await axios(
//         //   `${BASE_URL}/api/user/authorize?permission=${allowedRoles}`,
//         //   {
//         //     method: "GET",
//         //     headers: {
//         //       Authorization: token,
//         //     },
//         //   }
//         // );
//         // console.log(response.data);

//         // console.log(role)
//         // console.log(allowedRoles)
//         // console.log(!allowedRoles.includes(role))
//         // if (!allowedRoles.includes(role)) {
//         //   toast.error("You do not have access to this page", {
//         //     position: "top-right",
//         //     autoClose: 2000,
//         //     hideProgressBar: false,
//         //     closeOnClick: true,
//         //     pauseOnHover: true,
//         //     draggable: true,
//         //     progress: undefined,
//         //     theme: "light",
//         //     transition: Bounce,
//         //   });
//         //   // navigate("/");
//         // }
//         // if (!response.data.status) {
//           // toast.error("You do not have access to this page", {
//           //   position: "top-right",
//           //   autoClose: 2000,
//           //   hideProgressBar: false,
//           //   closeOnClick: true,
//           //   pauseOnHover: true,
//           //   draggable: true,
//           //   progress: undefined,
//           //   theme: "light",
//           //   transition: Bounce,
//           // });
//           // alert("You do not have access to this page")
//           // return navigate("/unauthorized_user")

//         // }

//       } catch (error) {
//         console.log(error);
//         toast.error("Please log in first", {
//           position: "top-right",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//           transition: Bounce,
//         });
//         localStorage.clear();
//         navigate("/login");
//       }
//     };
//     checkAuth();
//   }, [navigate]);

//   return <Component />;
// }

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "./config/baseUrl";
import { toast, Bounce } from "react-toastify";
import NoPermission from "./NoPermission";

export default function Protected({ Component,allowedRoles }) {
  const navigate = useNavigate(); 
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // console.log("*******",allowedRoles)
//     const fetchPermissions = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/user/verify-routes`, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         });
//         // console.log("protected",response)
//         setUserPermissions(response.data.data || []);
//         setLoading(false);
//       } catch (error) {
//         console.log("fetch permission error", error);
//         toast.error("Please log in first", {
//           position: "top-right",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//           transition: Bounce,
//         });
//         localStorage.clear();
//         navigate("/login");
//       }
//     };

//     const checkAuth = () => {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }
//       fetchPermissions();
//     };

//     checkAuth();
//   }, [navigate]);

//   if (loading) {
//     return <div>Loading...</div>; 
//   }
// // console.log("userp",userPermissions)
//  // Check if the current route is the dashboard route
//  const isDashboardRoute = window.location.pathname === "/";

//  if (isDashboardRoute) {
//    return <Component />;
//  }
// // let filtered = userPermissions.filter((item) => allowedRoles === item);
// // console.log("***11",filtered)
//   const hasPermission = userPermissions.includes(allowedRoles);
//   if (!hasPermission) {
//     return <NoPermission />; // Show the NoPermission component
//   }
  // const hasPermission = allowedRoles.map(role => userPermissions.includes(role));
  return <Component /> 
  // : <Navigate to="/" />;
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import trash from "../../assets/images/trash.png";
import edit from "../../assets/images/edit.png";
import view from "../../assets/images/view.png";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";
import QuestionImportCard from "./QuestionImportCard";

function Question_List() {
  let [data, setData] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [count, setCount] = useState(0);
  const [subject, setSubject] = useState([]);
  const [subjectOldData, setSubjectOldData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [selectInput, setSelectInput] = useState('');


  const handelOnChange = (e) => {
    let id=e?.target?.value
    setSelectInput(e?.target?.value)
    setShowPagination(false)
    if(!id){
      return alert("Please Select Subject")
    }
    // return
    let apicall = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/search/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data.data)
      if(response?.data?.data.length === 0){
        return alert("Subject Have No Qestions")
      }   
      setData(response?.data?.data);
      // setCount(response?.data?.data?.questionCount)
    };
    apicall();
  };

  useEffect(() => {
    const subjectData = async () => {
      let response = await axios(
        `${BASE_URL}/api/subject/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
       let filterData = response?.data?.data;
       filterData = response?.data?.data.filter((arr) => arr.isActive === true);
      setSubject(filterData);
      
    };
    subjectData();
  }, []);

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(
        // `http://localhost:1100/api/question/all_question?p=${pageNumber}`,
        `${BASE_URL}/api/question/all_question`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data)
      if(!(response?.data.status )){
        // setPageNumber(pageNumber-1)
        // return alert("Page Data Not Found ")
      }
      if(pageNumber === 1){
        setSubjectOldData(response?.data?.data?.allQuestion);
      }
      setData(response?.data?.data?.allQuestion);

      setCount(response?.data?.data?.questionCount)
    };
    apicall();
  }, [pageNumber]);

  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/delete_question/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let deletedUser = response?.data?.data?.deleteQuestion      ;
      setData(data.filter((arr) => arr._id !== deletedUser._id));
      setCount(response?.data?.data?.questionCount)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Question has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const handleActive = (activeId) => {
    let callApi = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/active/${activeId}`,
        {
          method: "PUT",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data);
    };
    callApi();
  };
  

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Question Bank
              </h4>
              <Link className="blu-btn" to="/question_add">
                Add Question
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
              
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Question List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <QuestionImportCard/>
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">Question List ({count})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action=""  >
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                     
                    <select name="subject_id"  onChange={handelOnChange} value={selectInput}  className="form-select">
                      <option value={""}>Select Subject</option>
                      {subject.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.sub_name}
                        </option>
                      ))}
                    </select>
                        </div> 
                        <div className="col-lg-2">
                          <button type="button" onClick={()=>{
                            setData([...subjectOldData])
                            setPageNumber(1)
                            setShowPagination(true)
                            setSelectInput("")
                            }}
                            className="blu-btn w-100">
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Question</th>
                      <th>option_a</th>
                      <th>option_b</th>
                      <th>option_c</th>
                      <th>option_d</th>
                      <th>SUbject</th>
                      <th>Answer</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{++i + 10 * (pageNumber - 1)}</td>
                        <td>{arr?.question}</td>
                        <td>{arr?.option_a}</td>
                        <td>{arr?.option_b}</td>
                        <td>{arr?.option_c}</td>
                        <td>{arr?.option_d}</td>

                        <td>{arr?.subject_id?.sub_name || "null"}</td>
                        <td>{arr?.answer}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={()=>handleActive(arr?._id)}
                              defaultChecked={arr?.isActive}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            className="mx-1"
                            to={`/question_edit/${arr?._id}`}
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            to={`/question_view/${arr?._id}`}
                          >
                            <img src={view} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  
                </table>
              {/* {  <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Question_List;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function React_Quill_Editor({
  disabled,
  data,
  seteditor,
  editorTitle,
  fieldName,
}) {
  let modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ 'color': [] }, { 'background': [] }],
      [
        { list: "ordered" }, 
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  return (
    <>
      
        <div>{editorTitle} </div>
        <ReactQuill theme="snow" className="react-quill"  modules={modules}  value={data} onChange={(e)=>(seteditor((curval)=>({...curval,[fieldName]:e})))} />
       
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";
const BannershowList = () => {
  const [pop, setPop] = useState([]);
  const [bannerStatus, setbannerStatus] = useState([]);
  let [updateSequence, setUpdateSequence] = useState();

  useEffect(() => {
    fetchData();
  }, []);
  // fetch All records
  const fetchData = async () => {
    const response = await axios.get(`${BASE_URL}/api/bannershow/allData`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    // console.log("bannerresponse", response.data);
    // Sort banners by sequence in ascending order
    const banners = response.data.data;
    const sortedBanners = banners.sort((a, b) => a.sequence - b.sequence);

    setPop(sortedBanners);
    // setPop(response.data.data);
    const initialStatus = banners.map((banner) => ({
      id: banner._id,
      show_yes_no: banner.show_yes_no,
    }));
    // console.log("initalstatus", initialStatus);

    setbannerStatus(initialStatus);
  };
  // formate Date
  const formatDate = (dateString) => {
    // console.log("first", dateString);
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based
    const year = date.getFullYear();
    // console.log(month < 10 ? "0" + month : month)
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  };

  //delete banner api
  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.delete(
          `${BASE_URL}/api/bannershow/delete/${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        
        // Update state
        const deletedUser = delResponse.data;
        setPop(pop.filter((user) => user._id !== deletedUser._id));
        
        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        
        // Refresh the data
        fetchData();
        
      } catch (error) {
        console.error("Error deleting banner:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the banner.",
          icon: "error",
        });
      }
    }
  };
  

  // banner status change api
  const handleBannerStatus = async (id) => {
    // console.log("id", id);
    let statusresp = await axios.put(
      `${BASE_URL}/api/bannershow/bannerstatuschange/${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log("bannerstatusresp", statusresp.data.data);
    const updatedStatus = bannerStatus.map((banner) =>
      banner.id === id
        ? { ...banner, show_yes_no: statusresp?.data?.data?.show_yes_no }
        : banner
    );
    // console.log("updated Status", updatedStatus);
    setbannerStatus(updatedStatus);
    // console.log("status",userStatus)
  };

  // update sequence
  const handleSequenceUpdate = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/bannershow/updateSequence/${id}`,
        { sequence: updateSequence },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      // console.log("responsessequence", response.data.data);
      fetchData();
    } catch (error) {
      console.log("updating sequence error", error);
    }
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Banner List
              </h4>
              <Link to="/banneradd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Banner
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Banner
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-start">
                  <div className="col-lg-7">
                    <h4 className="table-head">Total Banner ({pop.length}) </h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Show Time</th>
                      <th>SEQUENCE</th>
                      <th>Show Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pop.map((curElem, index) => {
                      // console.log("imagecheck", curElem);
                      return (
                        <tr key={curElem._id}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={`${BASE_URL}/${curElem.image}`}
                              className="img-40"
                              alt="image"
                            />
                          </td>
                          <td>{formatDate(curElem.start_time)}</td>
                          <td>{formatDate(curElem.end_time)}</td>
                          <td>{curElem.show_time}</td>
                          <td>
                            <div className="d-flex table-box">
                              <input
                                type="number"
                                className="form-control"
                                defaultValue={curElem?.sequence}
                                onChange={(e) =>
                                  setUpdateSequence(e.target.value)
                                }
                                min={0}
                              />
                              <button
                                className="btn btn-success ms-2"
                                onClick={() =>
                                  handleSequenceUpdate(curElem._id)
                                }
                              >
                                Update
                              </button>
                            </div>
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                // defaultChecked={curElem.show_yes_no}
                                onChange={() => handleBannerStatus(curElem._id)}
                                checked={
                                  bannerStatus.find(
                                    (banner) => banner.id === curElem._id
                                  )?.show_yes_no
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/editbanner/${curElem._id}`}
                              className="mx-2"
                            >
                              <img src={edit} alt="" />
                            </Link>
                            <button className="mx-2">
                              <img
                                src={trash}
                                alt="delete"
                                onClick={() => handleDelete(curElem._id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannershowList;

import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import card_icon_01 from "../../assets/images/card-icon-01.svg";
import card_icon_02 from "../../assets/images/card-icon-02.svg";
import card_icon_03 from "../../assets/images/card-icon-03.svg";
import card_icon_04 from "../../assets/images/card-icon-04.svg";
import card_icon_05 from "../../assets/images/card-icon-05.svg";
import card_icon_06 from "../../assets/images/card-icon-06.svg";
import card_icon_07 from "../../assets/images/card-icon-07.svg";
import card_icon_08 from "../../assets/images/card-icon-08.svg";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import { Link } from "react-router-dom";
export default function Dashboard() {
  let [totalCount, setTotalCount] = useState({});

  useEffect(() => {
    let apiCall = async () => {
      try {
        let response = await axios(`${BASE_URL}/api/user/totalCount`, {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        // console.log(response);
        setTotalCount(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    apiCall();
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-home me-2" />
                Dashboard
              </h4>
            </div>
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="row">
              {/* <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="dash-card prple-clr">
                    <p>Total Page Views</p>
                    <h5>
                      <span className="counter">400</span> +
                    </h5>
                    <img src={card_icon_01} alt="" className="card-icon" />
                  </div>
                </a>
              </div> */}
              <div className="col-lg-3 col-md-6">
                <Link to="/user_list">
                  <div className="dash-card sky-clr">
                    <p>Total Users</p>
                    <h5>
                      <span className="counter">{totalCount?.userCount}</span>
                    </h5>
                    <img src={card_icon_02} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/video_course_list">
                  <div className="dash-card org-clr">
                    <p>Video Course </p>
                    <h5>
                      <span className="counter">{totalCount?.videoCount}</span>
                    </h5>
                    <img src={card_icon_03} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/live_video_course_list">
                  <div className="dash-card pnk-clr">
                    <p>Live Video Course</p>
                    <h5>
                      <span className="counter">
                        {totalCount?.livevideoCount}
                      </span>
                    </h5>
                    <img src={card_icon_04} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/test_list">
                  <div className="dash-card grn-clr">
                    <p>Total Test Series</p>
                    <h5>
                      <span className="counter">{totalCount?.testCount}</span>
                    </h5>
                    <img src={card_icon_05} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/video_course_category_list">
                  <div className="dash-card aqa-clr">
                    <p>Video Category </p>
                    <h5>
                      <span className="counter">
                        {totalCount?.videoCategoryCount}
                      </span>
                    </h5>
                    <img src={card_icon_06} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/test_category_list">
                  <div className="dash-card brwn-clr">
                    <p>Test Category </p>
                    <h5>
                      <span className="counter">
                        {totalCount?.testCategoryCount}
                      </span>
                    </h5>
                    <img src={card_icon_07} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/question_list">
                  <div className="dash-card lgt-grn-clr">
                    <p>Total Qestions </p>
                    <h5>
                      <span className="counter">
                        {totalCount?.questioncount}
                      </span>
                    </h5>
                    <img src={card_icon_08} alt="" className="card-icon" />
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="dash-card prple-clr">
                    <p>Sales Test Series </p>
                    <h5>
                      <span className="counter">0</span> +
                    </h5>
                    <img src={card_icon_01} alt="" className="card-icon" />
                  </div>
                </a>
              </div>
              <div className="col-lg-12">
                <div className="cards">
                  <div className="d-flex justify-content-between fltr mb-3">
                    <h4 className="dash-head">Earnings</h4>
                    <select name=" " id="" className="form-select">
                      <option value="">Day</option>
                      <option value="">Weak</option>
                      <option value="">Month</option>
                      <option value="">Year</option>
                    </select>
                  </div>
                  {/* <canvas id="chart" width={1000} height={400} /> */}
                  <canvas
                    id="chart"
                    width={1121}
                    height={448}
                    style={{ display: "block", width: 1016, height: 406 }}
                  />

                  {/* <canvas id="chart" width="1121" height="448" style="display: block; width: 1016px; height: 406px;"></canvas> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="cards">
              <h4 className="dash-head">Today's Earnings</h4>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Video Courses</td>
                    <td className="text-warning">55 sales</td>
                    <td className="text-end">₹3446</td>
                  </tr>
                  <tr>
                    <td>Test Series</td>
                    <td className="text-warning">55 sales</td>
                    <td className="text-end">₹3446</td>
                  </tr>
                  <tr>
                    <td>Other</td>
                    <td className="text-warning">55 sales</td>
                    <td className="text-end">₹3446</td>
                  </tr>
                </tbody>
              </table>
               
            </div>
            <div className="cards">
              <div className="d-flex justify-content-between fltr">
                <h4 className="dash-head">User Add</h4>
                <select name=" " id="" className="form-select">
                  <option value="">Day</option>
                  <option value="">Week</option>
                  <option value="">Month</option>
                  <option value="">Year</option>
                </select>
              </div>
              <canvas id="barChart" />
            </div>
            <div className="cards know-abt">
              <div className="know-abt-innr">
                <a
                  href="#"
                  className="watch-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#video-popup"
                >
                  <i className="fas fa-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade video"
        id="video-popup"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <iframe
                width="100%"
                height={500}
                src="https://www.youtube.com/embed/yAoLSRbwxL8?si=jQNYMNhkHAWel9v2"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
const TopicList = () => {
  const { courseId,subjectId} = useParams();
  // console.log("subjectId", subjectId);
  // console.log("courseId", courseId);

  const [topicData, setTopicData] = useState([]);
  console.log("topicDAta", topicData);
  useEffect(() => {
    fetchTopicData();
  }, []);
  const fetchTopicData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/offline-course-topic-withsubject`,
        {
          subject_id: subjectId,
          user_id: "22541",
          offline_live_course_id: courseId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("response", response);
      // console.log("topicresponseData", response.data.data);
      // console.log("topicresponseNewSubject", response.data.new_subject);
      // console.log("topicresponseSubject", response.data.subject);
      setTopicData(response.data.data);
    } catch (error) {
      console.log("error fetching subject Data", error);
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Topic List
              </h4>
              <Link to="/topicadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Topic
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Topic List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Total Topic ({topicData.length}) </h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>Topic Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topicData.map((curElem, index) => {
                      // console.log("topic list",curElem)
                      return (
                        <tr key={curElem.id}>
                          <th>{index + 1}</th>
                          <th>
                            <img
                              src={curElem.image}
                              className="img-40"
                              alt="image"
                            />
                          </th>
                          <th>
                            <Link to={`/commentlist/${curElem.offline_file.id}`} >{curElem.topic}</Link>
                          </th>
                          <th>
                            <Link
                              // to={`/editbanner/${curElem._id}`}
                              className="mx-2"
                            >
                              <img src={edit} alt="" />
                            </Link>
                            <button className="mx-2">
                              <img
                                src={trash}
                                alt="delete"
                                // onClick={() => handleDelete(curElem._id)}
                              />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopicList;

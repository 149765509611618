import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import view from "../../assets/images/view.png";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";

const AdminUserList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState([])
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (isSearching) {
      handleSearchSubmit();
    } else {
      fetchData()
    }
    ;
  }, [isSearching]);
  //fetch data api
  const fetchData = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/user/user-list`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("admin user data", response);
      setData(response.data.data.filter((arr) => arr?._id !== "66ed559ce638c27989f1bdf0"));
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  // search api 
  const handleSearchSubmit = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/user/search?name=${searchText}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      // console.log("responsse", response)
      setData(response.data.data)
    } catch (error) {
      console.log("searching error", error)
    }
  }

  const searchHandle = (e) => {
    e.preventDefault();
    setIsSearching(true)
  }
  // handle Reset Search
  const handleResetSearch = () => {
    setSearchText("");
    setIsSearching(false);
  };

  // handle delete api 
   const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.delete(
          `${BASE_URL}/api/user/delete/${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        // Update state
        const deletedUser = delResponse.data.data;
        // console.log('delete',deletedRole)
        setData(data.filter((user) => user._id !== deletedUser._id));

        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        // Refresh the data
        fetchData();
      } catch (error) {
        console.error("Error deleting admin user:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the admin user.",
          icon: "error",
        });
      }
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Admin Users List
              </h4>
              <Link to="/adminuserregister" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Admin User
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Admin Users
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-6">
                    <h4 className="table-head">Total Admin Users </h4>
                  </div>
                  <div className="col-lg-6">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name="search"
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                          />
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="blu-btn me-2"
                            onClick={searchHandle}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="blu-btn"
                            onClick={handleResetSearch}
                          >
                            Reset Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>NAME</th>
                      <th>EMAIL</th>
                      <th>MOBILE</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => {
                      return (
                        <tr key={arr._id}>
                          <td>{++i}</td>
                          <td>{arr?.name}</td>
                          <td>{arr?.email}</td>
                          <td>{arr?.mobile}</td>
                          <td>
                            <Link
                              to={`/adminuseredit/${arr._id}`}
                              className="mx-2"
                            >
                              <img src={edit} alt="" />
                            </Link>
                            <Link
                              className="mx-2"
                              to=""
                              onClick={() => handleDelete(arr?._id)}
                            >
                              <img src={trash} alt="delete" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <div className="custom-pagination">
                //   <ul className="pagination">
                //     <li className="page-item">
                //       <button
                //         className="page-link"
                //         disabled={
                //           active
                //             ? searchNumber <= 1 && true
                //             : pageNumber == 1 && true
                //         }
                //         aria-label="Previous"
                //         onClick={
                //           active
                //             ? () => setSearchNumber((prev) => prev - 1)
                //             : () => setPageNumber((prev) => prev - 1)
                //         }
                //       >
                //         <span aria-hidden="true">«</span>
                //       </button>
                //     </li>
                //     {!active && pageNumber > 1 && (
                //       <li className="page-item">
                //         <button
                //           className="page-link"
                //           disabled={pageNumber == 1 && true}
                //           aria-label="Previous"
                //           onClick={
                //             active
                //               ? () => setSearchNumber((prev) => prev - 1)
                //               : () => setPageNumber((prev) => prev - 1)
                //           }
                //         >
                //           <span aria-hidden="true">
                //             {active ? searchNumber - 1 : pageNumber - 1}
                //           </span>
                //         </button>
                //       </li>
                //     )}
                //     <li className="page-item">
                //       <a className="page-link active" href="#">
                //         {active ? searchNumber : pageNumber}
                //       </a>
                //     </li>
                //     <li className="page-item">
                //       <a
                //         className="page-link "
                //         href="#"
                //         onClick={
                //           active
                //             ? () => setSearchNumber((prev) => prev + 1)
                //             : () => setPageNumber((prev) => prev + 1)
                //         }
                //       >
                //         {active ? searchNumber + 1 : pageNumber + 1}
                //       </a>
                //     </li>
                //     <li className="page-item">
                //       <a
                //         className="page-link"
                //         href="#"
                //         onClick={
                //           active
                //             ? () => setSearchNumber((prev) => prev + 2)
                //             : () => setPageNumber((prev) => prev + 2)
                //         }
                //       >
                //         {active ? searchNumber + 2 : pageNumber + 2}
                //       </a>
                //     </li>
                //     <li
                //       className="page-item"
                //       onClick={
                //         active
                //           ? () => setSearchNumber((prev) => prev + 1)
                //           : () => setPageNumber((prev) => prev + 1)
                //       }
                //     >
                //       <a className="page-link" href="#" aria-label="Next">
                //         <span aria-hidden="true">»</span>
                //       </a>
                //     </li>
                //   </ul>
                 </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminUserList;

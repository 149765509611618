import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import Swal from "sweetalert2";

function RoleList() {
  let [role, setrole] = useState([]);
  //   let [data, setData] = useState([]);
  useEffect(() => {
    fetchRole();
  }, []);

  const fetchRole = async () => {
    try {
      let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log('responserole',response)
      let filterData=response?.data?.data.filter((arr)=>(arr?._id !== "66e96de848f29ee073c06e15"))
      // console.log(response?.data?.data);
      
      setrole(filterData);
    } catch (error) {
      console.log("error getting role", error);
    }
  };


  //delete role api
  // const handleDelete = async (id) => {
  //   // Show confirmation dialog
  //   const result = await Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   });

  //   // If confirmed, proceed with deletion
  //   if (result.isConfirmed) {
  //     try {
  //       // Make the DELETE request
  //       const delResponse = await axios.delete(
  //         `${BASE_URL}/api/role/role-delete/${id}`,
  //         {
  //           headers: {
  //             Authorization: localStorage.getItem("token"),
  //           },
  //         }
  //       );

  //       // Update state
  //       const deletedRole = delResponse.data.data;
  //       // console.log('delete',deletedRole)
  //       setrole(role.filter((role) => role._id !== deletedRole._id));

  //       // Show success message
  //       Swal.fire({
  //         title: "Deleted!",
  //         text: "Your file has been deleted.",
  //         icon: "success",
  //       });

  //       // Refresh the data
  //       fetchRole();
  //     } catch (error) {
  //       console.error("Error deleting role:", error);
  //       Swal.fire({
  //         title: "Error!",
  //         text: "There was a problem deleting the role.",
  //         icon: "error",
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Manage Roles
              </h4>
              <Link to="/roleadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add New Role
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/role-list">Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Roles
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">Roles List</h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>NAME</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {role?.map((arr, i) => {
                      return (
                        <tr key={arr._id} id={`${arr?._id}`}>
                          <td>{++i}</td>
                          <td>{arr?.name}</td>
                          <td>
                            <Link className="mx-2" to={`/roleview/${arr?._id}`}>
                              <img src={view} alt="role" />
                            </Link>
                            <Link className="mx-2" to={`/roleedit/${arr?._id}`}>
                              <img src={edit} alt="edit" />
                            </Link>
                            {/* <Link
                              className="mx-2"
                              to=""
                              onClick={() => handleDelete(arr?._id)}
                            >
                              <img src={trash} alt="delete" />
                            </Link> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RoleList;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import { baseUrl } from "../config/baseUrl";
import axios from "axios";
const LiveVideoCourseList = () => {
  const [liveData, setLiveData] = useState([]);
// console.log("liveData",liveData.length)
  useEffect(() => {
    fetchLiveData();
  }, []);

  const fetchLiveData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/offline-course-all`,
        // `/api/offline-course`,
        { user_id: "22541", type: "live" },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responselive", response.data.data);
      setLiveData(response.data.data);
    } catch (error) { 
      console.log("error", error);
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Live Video Course List
              </h4>
              <Link to="/live_video_course_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Live Video Course
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/livevideocourselist">
                      Live Video Course Management
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Live Video Course List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Live Video Course List ({liveData.length}) </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        {/* <div className="col-lg3"> */}
                        {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                          />
                        </div> */}
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Course Name"
                            // onChange={(e) =>
                            //   setSearchCourseName(e.target.value)
                            // }
                          />
                        </div>
                        {/* <div className="col-lg-3"><input type="text" className="form-control" onChange={(e)=>setSearchCategory(e.target.value)} placeholder="Category Name"/></div> */}
                        {/* <div className="col-lg-3"><input type="text" className="form-control" placeholder="Price"/></div> */}
                        {/* <div className="col-lg-3"><button type="button" className="blu-btn w-100">Search</button></div> */}
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>COURSE NAME</th>
                      <th>SEQUENCE</th>
                      <th>PRICE</th>
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveData.map((arr, i) => (
                      <tr key={arr.id}>
                        <th>{i + 1}</th>
                        <th>
                          <img
                            src={`${arr?.image}`}
                            className="img-40"
                            alt=""
                          />
                        </th>
                        <td><Link to={`/subjectshowlist/${arr?.id}`}>{arr?.title}</Link></td>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              name="sequence"
                              defaultValue={arr?.sequence}
                              //   onChange={(e) =>
                              //     setUpdateSequence(e.target.value)
                              //   }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              //   onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>₹{arr?.price}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={parseInt(arr?.status)}
                              //   onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            to="#"
                            // to={`/live_video_course_details/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={view} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // to={`/live_video_course_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // onClick={() => handleDelete(arr._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1 && true}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveVideoCourseList;

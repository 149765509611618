import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL,{baseUrl} from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
import Swal from "sweetalert2";
const ComboOfferList = () => {
  const [offerData, setOfferData] = useState([]);
  const [updateSequence, setUpdateSequence] = useState();
  useEffect(() => {
    fetchData();
  }, []);
 
  //   fetch api
  const fetchData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/combo-course-list`, {
      });
      // console.log("responsseoffer", response.data);
      const offerResponse = response.data.data.data;
      const sortedOffer = offerResponse.sort((a, b) => a.sequence - b.sequence);
      setOfferData(sortedOffer);
    } catch (error) {
      console.log("error in fetching data", error); 
    }
  };

  // update sequence api
  const handleSequenceUpdate = async (id) => {
    // console.log(id,"11")
    try {
      const response = await axios.post(
        `${baseUrl}/api/combo-course-sequence-change`,
        { id:id,sequence: updateSequence },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
        // console.log("response",response.data)
      fetchData();
    } catch (error) {
      console.log("updating sequence error", error);
    }
  };

  // handle status
  const handleofferStatus = async (id,currentStatus) => { 
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    try {
      let statusresp = await axios.post(
        `${baseUrl}/api/combo-course-status-change`,
        {id:id,status:newStatus},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("offerstatusresp", statusresp.data);
    } catch (error) {
      console.log("error handle status", error);
    }
  };

  //delete combo offer api
  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.post(
          `${baseUrl}/api/combo-course-delete`,{
            id:id
          },
          // {
          //   headers: {
          //     Authorization: localStorage.getItem("token"),
          //   },
          // }
        );

        // Update state
        console.log(delResponse,"resondse delete");
        
        const deletedUser = delResponse.data;
        // setOfferData(
        //   offerData.filter((offer) => offer.id !== deletedUser.id)
        // );

        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });

        // Refresh the data
        fetchData();
      } catch (error) {
        console.error("Error deleting banner:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the banner.",
          icon: "error",
        });
      }
    }
  };


  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Combo Offer List
              </h4>
              <Link to="/comboofferadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Combo Offer
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/comboofferlist">Combo Offer Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Combo Offer List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Combo Offer ({offerData.length})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    {/* <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            // onChange={(e)=>setSearchCategoryName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>title</th>
                      <th>Image</th>
                      <th>SEQUENCE</th>
                      <th>STATUS</th>
                      <th>Action</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {offerData?.map((arr, i) => {
                      // console.log("arr.status",arr.status)
                      return (
                        <tr key={arr.id}>
                        <th>{i + 1}</th>
                        <td>{arr?.title}</td>
                        <th>
                          <img
                            src={`${arr.front_image}`}
                            className="img-40"
                            alt=""
                          />
                        </th>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              name="categorySequence"
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              className="form-control"
                              defaultValue={arr?.sequence}
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleSequenceUpdate(arr.id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // defaultChecked={arr?.status}
                              defaultChecked={arr?.status === "active"}
                              onClick={() => handleofferStatus(arr.id,arr?.status )}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            to={`/comboofferedit/${arr.id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            className="mx-2"
                            onClick={() => handleDelete(arr.id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                        <td  > 
                          <Link className="blu-btn" 
                             to={{
                              pathname: `/combo_offer_add_course/${arr?.id}`,
                            }}
                          >
                          Add Course
                          </Link>
                        </td>
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
                {/* {data?.length >10 && showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComboOfferList;



import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";

export default function CmsEdit() {
  let navigate = useNavigate();
 let {id}=useParams()
  let [formval, setFormval] = useState({
    title: "",
    description:""
  });
  
  const handelOnChange = (e) => {
    setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //fetch deep link api
  const fetchData = async () => {
    try {
      let response = await axios(`${BASE_URL}/api/cms/view/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
         
      });
      setFormval({...response.data.data,id:response.data.data.  _id});
      // console.log("deepresp", response.data);
    } catch (error) {
      console.log("fetching deeplink error", error);
    }
  };


  const handelOnSubmit = (e) => {
    e.preventDefault();
    if (
      !formval.title ||
      !formval.description 
    ) {
      alert("all filed is required");
      return;
    }
     
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/cms/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: formval,
      });
      if(response.data.status){
        navigate("/cmslist");
      }else{
        alert("some error from api")
      }
    };
    apiCall();
  };
  return (
    <> 
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-book me-2" />
                Add CMS
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_category_list">CMS</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Add CMS
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                   
                    <React_Quill_Editor
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Description"}
                    />
                  
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                     Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
